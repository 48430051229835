
import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import SEO from '../components/seo';
import NotFoundPage from "../pages/404";

export default function ArticleTemplate({data}) {

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  let articleDate = new Date(data.prismicBlogPost.data.publishing_date);
  articleDate = articleDate.toLocaleString("en-EN", options);



  return (
    <>
      <NotFoundPage/>
    </>
  
  ) 
}

export const query = graphql`
  query ArticleTemplate($url: String!) {
  prismicBlogPost(url: {eq:$url}) {
    id
    url
    data {
      title {
        text
      }
      publishing_date
      cover_image {
        url
      }
      content {
        html
      }
    }
  }
}
`