import React, {useRef, useEffect} from 'react';
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function NotFoundPage({data}) {

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="not-found-page">
        <div>
          <h1 className="not-found-titleSansSerif"><span role="img" aria-label="emoji works">🚧</span> 404: Not Found <span role="img" aria-label="emoji works">🚧</span></h1>
          <p>The page doesn&#39;t exist, I'm sorry <span role="img" aria-label="emoji confused">🥴</span>
            <br></br>
            Don't worry, just click on the button right below to get back on track.
          </p>
          <span>
            <Link className="cta" to="/">Get back home</Link>
          </span>
        </div>
      </div>
    </Layout>
  )
}